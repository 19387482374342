<template>
  <v-dialog v-model="visible" max-width="650">
    <v-card v-if="event">
        <v-card-title>
            <span class="headline">Add a Daily/Weekly Target</span>
        </v-card-title>

        <v-card-text>
          <p>
            This will add a daily/weekly target challenge to this event. Each day or week someone reaches the set goal, he or she earns a badge. <!-- There are special badges for every multiple of 5 consecutive days as well (5, 10, 15...). -->
          </p>
          <p v-if="isDays">
            The streak challenge will start on the first day of this event. Multiple activities on the same calendar date are aggregated and can count combined towards the daily goal.
          </p>
          <p v-if="isWeeks">
            Each week will start on the first day of each week (typically Sunday).
          </p>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="request.name" 
                label="Name of the challenge"
                />
            </v-col>
            <v-col cols="6">
              <DateWithTimeZonePicker v-model="request.start_date" clearable :minDate="event.from" :maxDate="event.till" :timeZone="event.timezone" label="Start date" hint="Leave empty to start on the first day of this event" icon="" />
            </v-col>
          </v-row>

          
          <v-row>
            <v-col cols="6">
              <div class="">Select the type</div>
              <v-btn-toggle v-model="request.type" mandatory class="multiline-btns">
                <v-btn value="CONSECUTIVE_DAYS" small>
                  Days
                </v-btn>
                <v-btn value="CONSECUTIVE_WEEKS" small>
                  Weeks
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6">

              <DistanceTextArea
                v-model="request.target_count" 
                :mode="$helpers.UnitType.NUMBER"
                :label="generateSubleaderboards ? 'Number of weeks/months' : `Number of ${typeForDisplay}s`" 
                :hint="generateSubleaderboards ? '' : `Duration of this challenge (in ${typeForDisplay}s)`"
                />
            </v-col>

          </v-row>
          
          <v-row>
            <v-col cols="6">
              <div>Select the goal metric</div>
              <v-btn-toggle v-model="metric" mandatory class="multiline-btns">
                <v-btn value="TIME" small>
                  Active Time
                </v-btn>
                <v-btn value="STEPS" small>
                  Steps
                </v-btn>
                <v-btn value="DISTANCE" small>
                  Distance
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-if="metric == 'STEPS'"
                v-model="request.target_steps" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Number of steps (per ${typeForDisplay})`" 
                hint="A typical goal is between 5,000 and 10,000 steps per day"
                />
              <DistanceTextArea
                v-if="metric == 'TIME'"
                v-model="request.target_active_time_s" 
                :mode="$helpers.UnitType.NUMBER"
                :multiplier="60"
                :label="`Number of active minutes (per ${typeForDisplay})`" 
                hint="A typical goal is around 30 minutes per day"
                />
            <DistanceTextArea
              v-if="metric == 'DISTANCE'"
              v-model="request.target_distance_m" 
              :mode="$helpers.UnitType.DISTANCE"
              :unit="event.unit"
              :label="`Distance (per ${typeForDisplay})`" 
              />
            </v-col>

          </v-row>
          
          <v-checkbox 
            v-if="betaMode"
            v-model="showIncrementFields"
            label="Increase goal over time"
            persistent-hint
            hint="When enabled, the daily/weekly goal will be increased each week."
            class="mt-0"
            />
          <div v-if="showIncrementFields">
            <DistanceTextArea
              v-if="metric == 'STEPS'"
              v-model="request.increment_steps" 
              :mode="$helpers.UnitType.NUMBER"
              :label="`Increment steps (per week)`" 
              />
            <DistanceTextArea
              v-if="metric == 'TIME'"
              v-model="request.increment_active_time_s" 
              :mode="$helpers.UnitType.NUMBER"
              :multiplier="60"
              :label="`Increment active minutes (per week)`" 
              />
            <DistanceTextArea
              v-if="metric == 'DISTANCE'"
              v-model="request.increment_distance_m" 
              :mode="$helpers.UnitType.DISTANCE"
              :unit="event.unit"
              :label="`Increment distance (per week)`" 
              />
          </div>

          <v-checkbox 
            v-model="request.personal_goal"
            label="Allow Personal Goals"
            persistent-hint
            hint="When enabled, participants can set their own personal goal instead of above default goal."
            class="mt-0"
            />
          
          <v-checkbox 
            v-if="betaMode"
            v-model="request.on_demand"
            label="Create as On-Demand leaderboard"
            persistent-hint
            hint="When enabled, participants can start this leaderboard upon request."
            class="mt-0"
            />
          
          <v-checkbox 
            v-if="betaMode"
            v-model="request.weekly_aggregation"
            label="Create a weekly aggregated result"
            persistent-hint
            hint="When enabled, results are aggregated weekly based on a custom formula."
            class="mt-0"
            />
          
          <v-checkbox 
            v-if="betaMode"
            v-model="generateSubleaderboards"
            label="Create multiple leaderboards"
            persistent-hint
            hint="When enabled, you can generate multiple weekly/monthly leaderboards in one go."
            class="mt-0"
            />
          <ToggleButtonInput
            v-if="generateSubleaderboards"
            v-model="request.periodicity"
            label="How many leaderboards do you want to create?"
            :items="[{type:'weekly', text: 'Weekly'}, {type:'monthly', text: 'Monthly'}]"
            />
          <v-alert outlined v-if="!isValid" type="info" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn v-if="generateSubleaderboards" :disabled="!isValid" color="blue darken-1" outlined @click="createSubleaderboards">Create Challenges</v-btn>
          <v-btn v-else :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)" :loading="$store.getters.isLoading">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SelectTrainingPlanDialog",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    ToggleButtonInput,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      tenant: tenant,
      visible: false,
      showIncrementFields: false,
      generateSubleaderboards: false,
      metric: null,
      request: { 
        name: null,
        type: 'CONSECUTIVE_DAYS',
        target_count: 0,
        target_active_time_s: null,
        target_steps: null,
        personal_goal: false,
        on_demand: false,
        periodicity: 'monthly',
      },
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
      this.visible = true;
      if (this.event && this.event.activity_mode == "STEP_BASED") {
        this.metric = "STEPS";
      }
      if (this.event && this.event.activity_mode == "ACTIVITY_BASED") {
        this.metric = "TIME";
      }
    },
    async createSubleaderboards() {
      const request = {
        name_template: this.request.name,
        periodicity: this.request.periodicity,
        daily_target: this.request.target_steps,
        scoring: this.metric,
      };
      const response = (await eventManagerService.addSubleaderboards(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Sub-leaderboards have been created.');
      this.eventUtil.refresh();
      this.visible = false;
    },
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    isDays() { return this.request.type == 'CONSECUTIVE_DAYS';},
    isWeeks() { return this.request.type == 'CONSECUTIVE_WEEKS';},
    typeForDisplay() { return this.isDays ? 'day' : 'week';},
    isValid() {
      return this.request.name && this.request.type && this.request.target_count > 0 && (this.request.target_active_time_s || this.request.target_steps || this.request.target_distance_m);
    }
  },
};
</script>
<style lang="scss" scoped>
  
</style>

