<template>  
  <span>
    <v-chip v-if="mode === 'STEP_BASED'" title="Only steps may count" color="blue darken-2" dark :small="small" class="" ><v-icon color="white" small :x-small="small" class="me-1">fa-shoe-prints</v-icon> Step-based</v-chip>
    <v-chip v-else-if="mode === 'ACTIVITY_BASED'" title="Only explicitly tracked activities may count" color="orange darken-2" dark :small="small" class=""><v-icon color="white" small :x-small="small" class="me-1">fa-stopwatch</v-icon>Activity-based</v-chip>
    <v-chip v-else-if="mode === 'CLAIM'" title="Users can log their wellbeing goals" color="yellow darken-2" dark :small="small" class=""><v-icon color="white" small :x-small="small" class="me-1">fa-spa</v-icon>Wellbeing</v-chip>
    <v-chip v-else-if="mode === 'CUSTOM'" title="Using event-specific activity types" color="green darken-2" dark :small="small" class="">Custom</v-chip>
    <v-chip v-else-if="mode === 'INVARIANT'" title="No activity mode set" color="grey darken-2" dark :small="small" class="">Neutral</v-chip>
    <v-chip v-else-if="mode === 'MIXED'" title="Both steps and activities may count" color="grey darken-2" dark :small="small" class="">Mixed Mode</v-chip>
    <v-chip v-else outlined color="grey" :small="small" class="">Unknown</v-chip>
    <v-btn v-if="!small" small :x-small="small" icon @click.stop="" target="_blank" href="https://corporatefitness.helpscoutdocs.com/article/24-activity-based-vs-step-based-leaderboards" title="More info about different challenge modes" ><v-icon color="grey"  small :x-small="small" class="">fa-info-circle</v-icon></v-btn>
    <span v-if="conversionMode && conversionMode !== 'NO_CONVERSIONS'">
      +
      <v-chip v-if="conversionMode === 'AUTO_CONVERSIONS'" title="Auto-conversions are enabled" color="grey darken-2" outlined class="">Auto Conversions</v-chip>
      <v-chip v-else-if="conversionMode === 'CUSTOM'" title="Custom conversions are configured for this event" color="grey darken-2" outlined class="">Custom Conversions</v-chip>
      <v-chip v-else color="grey darken-2" dark class="">Unknown conversions</v-chip>
      <v-btn  v-if="!small" small :x-small="small" icon @click.stop="" target="_blank" href="https://corporatefitness.helpscoutdocs.com/article/105-step-conversion" title="More info about activity conversions"><v-icon color="grey"  small :x-small="small" class="">fa-info-circle</v-icon></v-btn>

    </span>
  </span>
</template>

<script>
export default {
  name: "ActivityModeChip",
  components: {
  },
  props: {
    mode: String,
    conversionMode: String,
    small: Boolean,
  },
  mounted() {
    console.log('mode', this.mode);
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
  }
};
</script>

<style lang="scss">
</style>